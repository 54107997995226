import React, { Component } from "react";
import Galerie from "../galerie";
import Projets from "../projets";
import Localisation from "../localisation";
import Acceuil from "../acceuil";
import Plans from "../plans";
import Header from "../../websitelayout/Header";
import Description from "../decription";
import Video from "../video";

import "./index.css";
import $ from "jquery";
import ProjectService from "../../../services/project-service";
import MediaQuery from "react-responsive"
import SlideHomeMobile from "../../acceuil/Slide-home-Mobile";
import HeaderMobile from "../../websitelayout/HeaderMobile";
import Noprojet from "../nosprojet";
import Atouts from "../atouts";

export class index extends Component {
  constructor(props) {
    super(props);
    this.projectService = new ProjectService();
    this.state = { projet: {}, projects: [], ref: "CH28469", status: false };
  }
  async componentDidMount() {
    $(document).ready(() => {
      $('.modal-backdrop').remove();
      $("body").removeClass();
      $("body").addClass("magic-house");
      $(window).scrollTop(0);
    });

    const ref = "CH28469";
    
    await this.projectService.getProjectInfos(ref).then(data => {
      const planOfMasse = [data["data"].project.planOfMasse];
      this.setState({
        project: { ...data["data"].project, planOfMasse },
        status: true
      });
    });
  
    // await this.projectService.getProjects(ref).then(data => {
    //   this.setState({ projects: data["data"].projects, status: true });
    // });
  }

  render() {
    $("body").removeAttr("style");

    return (
      <div>
        <MediaQuery maxDeviceWidth={790}>
          {/* mobile */}
          <HeaderMobile project={this.state.project}/>
          <div
              className="container-fluid"
              style={{backgroundColor: "white", padding: 0}}
          >
            <Acceuil project={this.state.project}/>
          </div>

          <div className="section-padding desc" style={{backgroundColor: "white"}}>
            <br/>
            <Description project={this.state.project}/>
            <br/>
          </div>
          <div className="video" style={{backgroundColor: "white"}}>
            <Video project={this.state.project}/>
          </div>
          {this.state.project ? (
              <div
                  className="section-padding Galerie"
                  style={{backgroundColor: "grey"}}
              >
                <div className="row">
                  <div className="zone-title">
                    <h2 className="title">Galerie</h2>
                  </div>
                </div>
                <br/>
                <Galerie project={this.state.project}/>
                <br/>
                <br/>
              </div>
          ) : (
              <div></div>
          )}
          {this.state.project ? (
              <div
                  className="plans section-padding"
                  style={{
                    // backgroundImage: `url(${image})`,
                    backgroundColor: "white"
                  }}
              >
                <div className="row">
                  <div className="zone-title">
                    <h2 className="title">NOS PLANS</h2>
                  </div>
                </div>
                <Plans project={this.state.project}/>
              </div>
          ) : (
              <div></div>
          )}
          <div
              className="section-padding atouts"
              style={{backgroundColor: "#fff"}}
          >
            <br/>
            <div className="row">
              <div className="zone-title">
                <h2 className="title">Nos Atouts</h2>
              </div>
            </div>
            <Atouts project={this.state.project}/>
            <br/>
          </div>

          <div className="section-padding maps" style={{backgroundColor: "white"}}>
            <br/>
            <div className="row">
              <div className="zone-title">
                <h2 className="title">NOUS SOMMES ICI</h2>
              </div>
            </div>
            <Localisation project={this.state.project}/>
            <br/>
          </div>

          {this.state.project ? (
              <div className="mobile-slide">
                <div
                    className="section-padding projets"
                    style={{backgroundColor: "#fff"}}
                >
                  <div className="row">
                    <div className="zone-title">
                      <h2 className="title">Nos projets</h2>
                    </div>
                  </div>
                </div>
                <SlideHomeMobile projects={this.state.project.nosProjets}/>
              </div>) : (<div></div>)}
        </MediaQuery>
        <MediaQuery minDeviceWidth={790}>
          {/* desktop */}
          <Header project={this.state.project} projects={this.state.projects}/>
          <Noprojet/>
          <div
              className="container-fluid"
              style={{backgroundColor: "white", padding: 0}}
          >
            <Acceuil project={this.state.project}/>
          </div>

          <div className="section-padding desc" style={{backgroundColor: "white"}}>
            <br/>
            <Description project={this.state.project}/>
            <br/>
          </div>
          <div
              className="section-padding Galerie"
              style={{backgroundColor: "grey"}}
          >
            <div className="row">
              <div className="zone-title">
                <h2 className="title">Galerie</h2>
              </div>
            </div>
            <br/>
            <Galerie project={this.state.project}/>
            <br/>
            <br/>
          </div>
          <div
              className="plans section-padding"
              style={{
                // backgroundImage: `url(${image})`,
                backgroundColor: "white"
              }}
          >
            <div className="row">
              <div className="zone-title">
                <h2 className="title">NOS PLANS</h2>
              </div>
            </div>
            <Plans project={this.state.project}/>
          </div>
          <div className="video" style={{backgroundColor: "white"}}>
            <Video project={this.state.project}/>
          </div>
          <div
              className="section-padding atouts"
              style={{backgroundColor: "#fff"}}
          >
            <br/>
            <div className="row">
              <div className="zone-title">
                <h2 className="title">Nos Atouts</h2>
              </div>
            </div>
            <Atouts project={this.state.project}/>
            <br/>
          </div>
          <div className="section-padding maps" style={{backgroundColor: "white"}}>
            <br/>
            <div className="row">
              <div className="zone-title">
                <h2 className="title">NOUS SOMMES ICI</h2>
              </div>
            </div>
            <Localisation project={this.state.project}/>
            <br/>
          </div>
          {this.state.project ? (
              <div
                  className="section-padding projets"
                  style={{backgroundColor: "grey"}}
              >
                <div className="row">
                  <div className="zone-title">
                    <h2 className="title">Nos projets</h2>
                  </div>
                </div>
                <br/>
                <Projets projects={this.state.project.nosProjets}/>

                <br/>
                <br/>
                <br/>
              </div>
          ) : (<div></div>)}
        </MediaQuery>
      </div>
    );
  }
}

export default index;
